import React from 'react'

/**
 * Types and Interface
 */
import {
  PostInterface,
  DefaultComponentProps,
}
from '@interface/common'

/**
 * Helpers
 */
import {pure} from '@react/derive'
import string from '@helpers/string'
import {useState} from '@react/hooks'
import {
  http,
  ellipsis,
  datetime,
}
from '@helpers/common'

/**
 * Components
 */
import Checkbox from '@components/checkbox'
import TableBottom from '@components/table/bottom'

/**
 * Images
 */
import Spinner from '@assets/images/spinner.gif'


/**
 * Reported Screen
 */
export default pure(({user, modal}: DefaultComponentProps) => {
  const state = useState({
    post: {},
    user: {
      role: null
    },
    posts: [],
    selected: [],
    limit: 20,
    type: null,
    error: false,
    block: false,
    approve: false,
    loading: true,
    fetching: true,
    moderator: null,
    title: 'Reported Post',
    content: [
      {
        type: 'paragraph',
        children: [
          {text: ''},
        ],
      }
    ]
  })

  /**
   * Get users
   */
  React.useEffect(() => {
    posts()
  }, [])

  
  /**
   * Is checked?
   */
  function isChecked() {
    if(state.selected.length === 0) {
      return false
    }
    return state.selected.length === state.posts.length ? true : false
  }


  /**
   * Get post
   */
  function post(item: PostInterface) {
    /**
     * Send request
     */
    http.get(`/posts/${item.pid}`)

    .then(({status, result}) => {
      if(status === 200) {
        state.set({post: result, loading: false})
      }
    })
    .catch(e => {
      state.set({error: e.data.result.error})
    })
    state.set({review: true, loading: true})
  }


  /**
   * Get user
   */
  function posts() {
    /**
     * Send request
     */
    http.get(`/posts`, {
      query: {
        type: state.type,
        limit: state.limit,
        start: state.start,
        moderator: state.moderator,
        user: {
          uid: user.uid,
          role: user.organization.role,
        },
        reported: true,
      }
    })
    .then(({result}) => {
      if(result) {
        state.set({posts: result, error: false, fetching: false})
      }
      else {
        state.set({posts: [], fetching: false})
      }
    })
    .catch(e => {
      console.log(e)
    })
    state.set({fetching: true})
  }


  return (
    <div className="inner">
      <header>
        <div className="left">
          <h3 className="margin-0">Reported</h3>
        </div>
        <div className="right filter">
          <span className="select">
            <label>Type</label>
            <select onChange={(e) => state.set({type: e.target.value})}>
              <option value="">All</option>
              <option value="text">Text</option>
              <option value="share">Share</option>
              <option value="link">Link</option>
              <option value="media">Media</option>
            </select>
          </span>
          <span onClick={() => posts()} className="button-3">Filter</span>
        </div>
      </header>
      <div className="table">
        <table>
          <thead>
            <tr>
              <th>
                <Checkbox
                  space={30}
                  onClick={(checked: boolean) => {
                    state.set({
                      selected: state.posts.reduce(
                        (items: [], item: any) => {
                          if(checked) {
                            items.push(item.pid as never)
                          }
                          else {
                            return items.filter((v: string) => v !== item.pid)
                          }
                          return items
                        },
                      [])
                    })
                  }}
                  checked={isChecked()}
                />
              </th>
              <th>Caption</th>
              <th>Message</th>
              <th>Issue</th>
              <th>Type</th>
              <th>Reported</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {state.fetching ? (
              <tr>
                <td colSpan={5} style={{textAlign: 'center'}}>
                  <img alt={'Spinner'} src={Spinner} style={{width: 32, padding: 20}}/>
                </td>
              </tr>
            ):(
              <>
                {state.posts.length > 0 ? (
                  <>
                    {state.posts.map((item: PostInterface, key: number) => {
                      return (
                        <tr key={key}>
                          <td style={{width: 20}}>
                            <Checkbox
                              space={30}
                              onClick={(checked: boolean) => {
                                state.set(({selected}: any) => {
                                  if(checked) {
                                    selected.push(item.pid)
                                  }
                                  else {
                                    selected = selected.filter((v: string) => v !== item.pid)
                                  }
                                  return {selected}
                                })
                                
                              }}
                              checked={state.selected.includes(item.pid)}
                            />  
                          </td>
                          <td style={{maxWidth: 300}}>
                            {item.text ? ellipsis(item.text, 15) : (
                              <span style={{fontStyle: 'italic', color: '#bbb'}}>n/a</span>
                            )}
                          </td>
                          <td>{item.reported.message}</td>
                          <td>{item.reported.title}</td>
                          <td>{string.toUCFirst(item.type)}</td>
                          <td style={{width: 250}}>{datetime(item.reported.timestamp).fullDate()}</td>
                          <td style={{width: 100}}>
                            <span
                              className="button-1"
                              onClick={() => {
                                modal.open({name: 'review', data: item})
                              }}>
                              Review
                            </span>
                          </td>
                        </tr>
                      )
                    })}
                  </>
                ):(
                  <tr>
                    <td colSpan={5} style={{textAlign: 'center'}}>
                      <span>No Results</span>
                    </td>
                  </tr>
                )}
              </>
            )}
          </tbody>
        </table>
      </div>
      <TableBottom
        entries={{
          onChange(e) {}
        }}
        pagination={{
          limit: state.limit,
          total: state.total,
          onClick(selected) {
            
          },
          onPrev() {
            
          },
          onNext() {
            
          },
        }}
      />
    </div>
  )
})