/**
 * Helpers
 */
import {pure} from '@react/derive'
import {datetime} from '@helpers/common'
import {UserInterface, DefaultComponentProps} from '@interface/common'

/**
 * Images
 */
import Close from '@assets/images/close.png'

/**
 * Export a certain modal
 */
export default pure(({modal, data}: {data:any} & DefaultComponentProps) => {

  return (
    <div
      className="account-detail"
      onClick={(e) => {
        e.stopPropagation()
      }}>
      <div className="head">
        <h2>Account Detail</h2>
        <span
          className="close"
          onClick={modal.close}>
          <img alt="Close" src={Close}/>
        </span>
      </div>
      <div className="detail">
        <div className="personal">
          <ul className="clear">
            <li>
              <p><strong>Name</strong></p>
              <p>{data.firstname} {data.lastname}</p>
            </li>
            <li>
              <p><strong>Email</strong></p>
              <p>{data.email}</p>
            </li>
            <li>
              <p><strong>Phone</strong></p>
              <p>{data.phone ? data.phone : 'n/a'}</p>
            </li>
            <li>
              <p><strong>Created</strong></p>
              <p>{datetime(data.created).fullDate()}</p>
            </li>
          </ul>
        </div>


        <div className="sub-accounts">
          <h4>Sub Accounts ({data.subs.length})</h4>

          <ul className="clear">
            <li>
              <span><strong>Name</strong></span>
              <span><strong>Created</strong></span>
            </li>
            {data.subs.map(
              (item:UserInterface, index:number) => {
                const date = datetime(item.created)
                return (
                  <li key={index}>
                    <span className="name">{item.firstname} {item.lastname}</span>
                    <span className="created">{date.fullDate()}</span>
                  </li>
                )
              }
            )}
          </ul>
        </div>
      </div>
    </div>
  )
})