import config from '@config/common'
import storage from '@helpers/storage'
import {HttpRequest} from '@helpers/http'



/**
 * Http Request
 */
export const http = HttpRequest(config.env.ENDPOINT, ((name) => {
  if(name) {
    var store = storage.parse(name)
    if(store && store.detail) {
      return JSON.parse(store.detail)
    }
  }
})
(config.app.storage.name))



/**
 * Cut the length of text
 * @returns {string}
 */
export function ellipsis(text: string, len: number = 10) {
  if(text) {
    let arr = text.split(' ')
    if(arr.length > len) {
      return `${arr.slice(0, len).join(' ')}...`
    }
  }
  return text
}


/**
 * Convert datetime
 * @param ts 
 */
export function datetime(ts: string | number) {
  var date: any = new Date(ts)
  var week = [
    'Sun',
    'Mon',
    'Tue',
    'Wed',
    'Thu',
    'Fri',
    'Sat'
  ]
  var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']

  /**
   * Time with 12 hours format
   */
  var toTime = (format = true) => {
    var h = date.getHours()
    var m: any = date.getMinutes()

    h = h % 12;
    h = h ? h : 12
    m = m < 10 ? '0'+m : m

    let f = ''
    if(format) {
      f = ` ${date.getHours() >= 12 ? 'PM' : 'AM'}`
    }
    return `${h}:${m}${f}`
  }
  return {
    toTime,
    fullDate: () => {
      var _date = [
        months[date.getMonth()],
        date.getDate(),
        date.getFullYear()
      ]
      return `${week[date.getDay()]}, ${_date.join(' ')} at ${toTime()}`
    }
  }
}



/**
 * Validate
 */
export const validate = {
  email: (text: string) => {
    if(text.match(/([a-z0-9.]+)@([a-z0-9-]+)\.([a-z]{2,3})/)) {
      return true
    }
  },
  passwordStrength: (password: string, email: any = null, length = 10) => {
    let nu = [], lc = [], uc = [], sp = [], at = []
 
    /**
     * Password should not in the email
     */
    if(email) {
      email = email.split('@')

      if(password.match(email[0])) {
        return 'Password must not be found in your email address.'
      }
    }
    /**
     * Check password length
     */
    if(password.length < length) {
      return `Password must be at least ${length} characters long.`
    }


    for(let i = 0, j = password.length; i < j; i++) {
      let text = password.substr(i, 1)
      /**
       * Check if has special character
       */
      let sm = text.match(/^[\W]$/)
      if(sm) {
        sp.push(sm[0])
      }
      /**
       * Check if has number
       */
      let nm = text.match(/^[\d]$/)
      if(nm) {  
        nu.push(nm[0])
      }
      /**
       * Check lowercase
       */
      let lm = text.match(/^[a-z]$/)
      if(lm) {  
        lc.push(lm[0])
      }
      /**
       * Check uppercase
       */
      let um = text.match(/^[A-Z]$/)
      if(um) {
        uc.push(um[0])
      }
    }

    /**
     * Errors
     */
    if(uc.length < 3) {
      at.push('3 upper case')
    }
    if(lc.length < 3) {
      at.push('3 lower case')
    }
    if(nu.length < 2) {
      at.push('2 numeric characters')
    }
    if(sp.length < 2) {
      at.push('2 special characters')
    }

    if(at.length > 0) {
      if(at.length === 1) {
        return `Password must be at least ${at[0].toLocaleLowerCase()}`
      }
      else {
        return `Password must be at least ${at.join(', ')}`
      }
    }
    return true
  }
}
