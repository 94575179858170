import React from 'react'
/**
 * Helpers
 */
import {pure} from '@react/derive'
import {http} from '@helpers/common'
import {useState} from '@react/hooks'
import {DefaultComponentProps} from '@interface/common'

/**
 * Configuration
 */
import config from '@config/common'

/**
 * Components
 */
import Button from '@components/button'

/**
 * Images
 */
import Close from '@assets/images/close.png'

/**
 * Export a certain modal
 */
export default pure(({user, store, modal}: DefaultComponentProps) => {
  const state = useState({
    tab: [
      'Add or Import',
      'Add Existing Account',
    ],
    role: 'Member',
    file: null,
    email: null,
    error: null,
    activeTab: 0,
    lastname: null,
    firstname: null,
  })
  
  function submit() {
    const org = modal.data() ?? user.organization
    http.post('/accounts', {
      data: {
        file: state.file,
        user: {
          email: state.email,
          lastname: state.lastname,
          firstname: state.firstname,
        },
        org: {
          oid: org.oid,
          type: org.type,
          name: org.name,
          role: state.role,
        }
      },
      headers: {
        'Content-Type': 'multipart/form-data;'
      }
    })
    .then(({status, result}) => {
      if(status === 201 || status === 200) {
        store.dispatch({account: result, modal: null})
      }
    })
    .catch(e => console.log(e))
  }

  return (
    <div
      className="account-import"
      onClick={(e) => {
        e.stopPropagation()
      }}>
      <div className="head">
        <h2>Add Account</h2>
        <span
          className="close"
          onClick={modal.close}>
          <img alt="Close" src={Close}/>
        </span>
      </div>
      <div>
        <p>Add one account or import more.</p>
        <div className="tab">
          {state.tab.map((item:string, key:number) => {
            return (
              <span
                key={key}
                className={key === state.activeTab ? 'active' : ''}
                onClick={() => {
                  state.set({activeTab: key})
                }}>
                {item}
              </span>
            )
          })}
        </div>
        {state.activeTab === 0 ? (
          <React.Fragment>
            <div>
              <div className="field">
                <label>First Name</label>
                <input type="text" onChange={(e) => state.set({firstname: e.target.value})} required/>
              </div>
              <div className="field">
                <label>Last Name</label>
                <input type="text" onChange={(e) => state.set({lastname: e.target.value})} required/>
              </div>
              <div className="field">
                <label>Email Address</label>
                <input type="email" onChange={(e) => state.set({email: e.target.value})} required/>
              </div>
            </div>
            <div style={{textAlign: 'center', margin: '20px 0'}}><strong>Or</strong></div>
            <div style={{marginBottom: 40}}>
              <label
                htmlFor="file"
                className={'button-1'}
                style={{
                  display: 'block',
                  marginBottom: 10,
                }}>
                <input
                  id="file"
                  type="file"
                  style={{
                    display: 'none'
                  }}
                  onChange={(e) => {
                    const files = e.target.files
                    if(files) {
                      const file = files[0]

                      if(file.type === 'text/csv') {
                        state.set({file})
                      }
                      else {
                        state.set({
                          error: 'Sorry, the supported format is only .csv (comma-separated value).'
                        })
                      }
                    }
                  }}
                />
                <span>Import File (.csv)</span>
              </label>
              <center>
                {state.file ? (
                  <em>{state.file.name}</em>
                ):(
                  <em>The file must contain comma-separated values for firstname, lastname and email address. <br/>Example: John,Doe,johndoe@email.com</em>
                )}
              </center>
            </div>
          </React.Fragment>
        ):(
          <div>
            <div className="field">
              <label>Email Address</label>
              <input type="email" onChange={(e) => state.set({email: e.target.value})} required/>
            </div>
            <div className="field">
              <label>Role</label>
              <select
                onChange={(e) => {
                  state.set({role: e.target.value})
                }}>
                {config.app.roles.map((item, index) => {
                  return (
                    <option key={index} value={item}>{item}</option>
                  )
                })}
              </select>
            </div>
          </div>
        )}

        {state.error && (
          <p className="error">{state.error}</p>
        )}

        <Button
          style={{
            width: '100%',
          }}
          label={'Submit'}
          onClick={submit}
          className={'button-3'}
        />
      </div>
    </div>
  )
})