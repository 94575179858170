import React from 'react'
import {useNavigate} from 'react-router-dom'

/**
 * Helpers and types
 */
import {pure} from '@react/derive'
import {http} from '@helpers/common'
import string from '@helpers/string'
import {useState} from '@react/hooks'
import {datetime} from '@helpers/common'
import {SchoolType, DefaultComponentProps} from '@interface/common'


/**
 * Assets
 */
import './schools.scss'
import Spinner from '@assets/images/spinner.gif'


/**
 * Components
 */
import Search from '@components/search'
import Checkbox from '@components/checkbox'
import TableBottom from '@components/table/bottom'


type InviteType = {
  name: string
  email: string
}

/**
 * Schools Screen
 */
export default pure(({store, modal}: DefaultComponentProps) => {
  const state = useState({
    name: null,
    email: null,
    limit: 5,
    next: null,
    prve: null,
    status: null,
    current: 1,
    schools: [],
    selected: [],
    fetching: true,
  })
  const navigate = useNavigate()


  /**
   * Get schools
   */
  React.useEffect(() => {
    getSchools({limit: 5})
  }, [])


  /**
   * Update the table
   */
  React.useEffect(() => {
    const school = store.get('school')
    
    if(school) {
      state.set(({schools}:any) => {
        return {
          schools: [school].concat(schools)
        }
      })
    }
  }, [store])


  /**
   * Get current page
   */
  function page(name: string, page: string|number, last?: string|number) {
    if(page) {
      getSchools({last, [name]: page})
    }
  }


  /**
   * Remove
   */
  function remove(path:string, ids:string[]) {
    http.delete(`/${path}`, {
      query: {
        ids,
      }
    })
    .then(({status, result}) => {
      if(status === 200 && result) {
        state.set(({schools}:any) => {
          return {
            selected: [],
            schools: schools.filter((v: any) => !result.includes(v.oid))
          }
        })
      }
    })
    .catch(e => console.log(e))
  }


  
  /**
   * Is checked?
   */
  function isChecked() {
    if(state.selected.length === 0) {
      return false
    }
    return state.selected.length === state.schools.length ? true : false
  }


  /**
   * Get schools
   */
  function getSchools(query:any = {}) {
    http.get('/organizations', {
      query: {
        limit: state.limit,
        status: state.status,
        ...query,
      }
    })
    .then(({status, result}) => {
      if(status === 200) {
        state.set({
          fetching: false,
          total: result.total,
          schools: result.organizations ?? [],
        })
      }
    })
    .catch(e => console.log(e))
  }

  /**
   * Send invitation to incomplete accounts
   */
  function sendInvitation(schools:InviteType[]) {
    modal.open({
      name: 'sending',
      data: {
        title: 'Sending Invitation',
        message: `Please don't reload the page.`
      }
    })
    http.post('/organizations/invite', {
      data: {
        schools,
      }
    })
    .then(({status, result}) => {
      if(status === 200 && result) {

        const map = (item:SchoolType) => {
          item = {...item}
          if(result.includes(item.email)) {
            item.status = 'invited'
            return item
          }
          return item
        }
        setTimeout(() => {
          state.set(({schools}:any) => {
            return {
              schools: schools.map(map),
              selected: []
            }
          })
          modal.close()
        }, 2000)
      }
    })
    .catch(e => console.log(e))
  }


  return (
    <React.Fragment>
      <header>
        <div className="left inline">
          <h3 className="margin-0">Schools</h3>

          {state.selected.length > 0 && (
            <div className="buttons inline">
              <div
                className="button-3"
                onClick={() => {
                  sendInvitation(state.selected)
                }}>
                <span className="icon">
                  <i className="icon-plane-slope"></i>
                </span>
                <span className="text">Send Invitation ({state.selected.length})</span>
              </div>
              <div
                className="button-1"
                onClick={() => {
                  remove('organizations', state.selected.map((v:any) => v.oid))
                }}>
                <span className="icon">
                  <i className="icon-bin"></i>
                </span>
                <span className="text">Delete Pending ({state.selected.length})</span>
              </div>
            </div>
          )}
        </div>
        <div className="right inline">
          <span className="search">
            <label>Search</label>
            <Search
              type={'school'}
              onChange={(data: object[]) => {
                state.set({
                  schools: data
                })
              }}
            />
          </span>
          <div className="filter">
            <label>Status</label>
            <select
              onChange={(e) => {
                getSchools({status: e.target.value})
              }}>
              <option value="">All</option>
              <option value="active">Active</option>
              <option value="pending">Pending</option>
              <option value="invited">Invited</option>
            </select>
          </div>
          <div className="add-button">
            <span
              className="button-3"
              onClick={() => {
                modal.open('addSchool')
              }}>
              <span className="icon-plus"></span>
              <span className="text">Add School</span>
            </span>
          </div>
        </div>
      </header>
      <div className="table">
        <table>
          <thead>
            <tr>
              <th>
                <Checkbox
                  space={30}
                  onClick={(checked: boolean) => {
                    state.set({
                      selected: state.schools.reduce(
                        (items: any[], item: any) => {
                          if(checked) {
                            items.push({
                              oid: item.oid,
                              name: item.name,
                              email: item.email
                            })
                          }
                          else {
                            return items.filter((v: any) => v.oid !== item.oid)
                          }
                          return items
                        },
                      [])
                    })
                  }}
                  checked={isChecked()}
                />
              </th>
              <th>School Name</th>
              <th>Email Address</th>
              <th>Status</th>
              <th>Created</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {state.fetching ? (
              <tr>
                <td colSpan={5} style={{textAlign: 'center'}}>
                  <img alt={'Spinner'} src={Spinner} style={{width: 32, padding: 20}}/>
                </td>
              </tr>
            ):(
              <>
                {state.schools.length > 0 ? (
                  <>
                    {state.schools.map((item: SchoolType, key: number) => {
                      let date = datetime(item.created)
                      return (
                        <tr key={key}>
                          <td style={{width: 20}}>
                            <Checkbox
                              space={30}
                              onClick={(checked: boolean) => {
                                state.set(({selected}: any) => {
                                  if(checked) {
                                    selected.push(
                                      {oid: item.oid, name: item.name, email: item.email}
                                    )
                                  }
                                  else {
                                    selected = selected.filter((v: any) => v.oid !== item.oid)
                                  }
                                  return {selected}
                                })
                                
                              }}
                              checked={(
                                state.selected.filter((v: any) => v.oid === item.oid).length > 0
                              )}
                            />  
                          </td>
                          <td>{item.name}</td>
                          <td>{item.email}</td>
                          <td>
                            {string.toUCFirst(item.status)}
                          </td>

                          <td style={{width: 250}}>{date.fullDate()}</td>
                          <td style={{width: 100}}>
                            <div className="flex-end">
                              {item.status === 'invited' && (
                                <span className="button-5 opacity-05">Invited</span>
                              )}
                              {item.status === 'pending' && (
                                <span
                                  className="button-5"
                                  onClick={() => {
                                    sendInvitation([{name: item.name, email: item.email}])
                                  }}>
                                  <span>Send Invitation</span>
                                </span>
                              )}
                              <span
                                className="button-1"
                                onClick={() => {
                                  navigate(`/office/accounts`, {state: item})
                                }}>
                                <span>Detail</span>
                              </span>
                            </div>
                          </td>
                        </tr>
                      )
                    })}
                  </>
                ):(
                  <tr>
                    <td colSpan={6} style={{textAlign: 'center'}}>
                      <span>No Results</span>
                    </td>
                  </tr>
                )}
              </>
            )}
          </tbody>
        </table>
      </div>
      <TableBottom
        entries={{
          onChange(e) {
            getSchools({limit: e.target.value})
          }
        }}
        pagination={{
          limit: state.limit,
          total: state.total,
          onClick(selected) {
            page('page', selected, state.schools.at(-1)?.created)
          },
          onPrev() {
            page('prev', state.schools[0]?.created)
          },
          onNext() {
            page('next', state.schools.at(-1)?.created)
          },
        }}
      />
    </React.Fragment>
  )
})