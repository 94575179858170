/**
 * Helpers and types
 */
import {pure} from '@react/derive'
import {http} from '@helpers/common'
import {useState} from '@react/hooks'
import {DefaultComponentProps} from '@interface/common'


/**
 * Components
 */
import Button from '@components/button'

/**
 * Images
 */
import Close from '@assets/images/close.png'


/**
 * Schools Screen
 */
export default pure(({user, store, modal}: DefaultComponentProps) => {
  const state = useState({
    name: null,
    email: null,
  })

  
  function submit(e: any) {
    e.preventDefault()

    http.post('/organizations', {
      data: {
        org: {
          type: 'Education',
          name: state.name,
          email: state.email,
        },
        user: {
          email: user.email
        },
        ref: 'panel'
      }
    })
    .then(({status, result}) => {
      if(status === 201) {
        store.dispatch({school: result, modal: null})
      }
    })
    .catch(e => console.log(e))
  }

  return (
    <div
      className="add-school"
      onClick={(e) => {
        e.stopPropagation()
      }}>
      <div className="head">
        <h2>Add School</h2>
        <span
          className="close"
          onClick={modal.close}>
          <img alt="Close" src={Close}/>
        </span>
      </div>
      <form onSubmit={submit}>
        <div className="fields">
          <div className="field" style={{marginBottom: 10}}>
            <label>School Name</label>
            <input type="text" onChange={(e) => state.set({name: e.target.value})} required/>
          </div>
          <div className="field">
            <label>Email Address</label>
            <input type="email" onChange={(e) => state.set({email: e.target.value})} required/>
          </div>
        </div>
        <div className="action">
          <Button
            label={'Save'}
            style={{
              width: '100%'
            }}
            className={'button-3'}
          />
        </div>
      </form>
    </div>
  )
})